import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Stack,
  Grid,
  Typography,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
} from '@mui/material';
import { logoutUser } from './headerActions';
import { AccountCircleOutlined } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { set_user } from 'Store/reducers/userReducer';
import { pusher } from 'helpers/websocket';
import { setSuccessToastState } from 'Store/action';
import { AppDispatch } from 'Store/Store';
import { UserType } from 'shared/types/User.types';
const Background = require('App/Assets/images/background.png');

const drawerWidth = '250px';
const classes = {
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  content: {
    flexGrow: 1,
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundRosition: 'center',
    overflow: 'auto',
    height: '100vh',
  },
  sectionTitle: {
    fontSize: 14,
    color: '#E1261C',
    textTransform: 'uppercase',
  },
  link: {
    paddingLeft: '15px',
    pt: '25px',
    pb: '25px',
    borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
    '& div': {
      margin: 0,
    },
    '& div span': {
      color: '#fff',
      fontFamily: 'FS Jack',
      lineHeight: '22px',
    },
    '& div svg': {
      fill: '#fff',
    },
    '&:hover div svg': {
      fill: '#011E41',
    },
    '&:hover div span': {
      color: '#011E41',
      fontFamily: 'FS Jack',
    },
    '&:hover': {
      borderLeft: '2px solid #E1261C',
      backgroundColor: '#ffffff',
      paddingLeft: '13px',
      color: '#011E41',
    },
  },
  selectedNav: {
    borderLeft: '2px solid #E1261C',
    backgroundColor: '#ffffff',
    paddingLeft: '13px',
    pt: '20px',
    pb: '20px',
    '& div': {
      margin: 0,
    },
    '& div span': {
      color: '#011E41',
      fontFamily: 'FS Jack',
      lineHeight: '22px',
    },
    '&:hover div span': {
      color: '#011E41',
      fontFamily: 'FS Jack',
    },
    '&:hover': {
      borderLeft: '2px solid #E1261C',
      backgroundColor: '#ffffff',
      paddingLeft: '13px',
      color: '#011E41',
      fill: '#011E41',
    },
    '& div svg': {
      fill: '#011E41',
    },
  },
  topContainer: {
    minHeight: 70,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  myProfileListItem: {
    backgroundColor: '#40DED4',
    textTransform: 'uppercase',
    color: 'black',
    pl: '16px',
    pt: '20px',
    pb: '20px',
    '& div': {
      margin: 0,
    },
    '& svg': {
      transition: 'all 0.3s',
      fill: '#000000',
    },
    '&:hover': {
      fontWeight: 'bold',
      color: '#3e3f60',
      borderLeft: '6px solid #40DED4',
      backgroundColor: '#ffffff',
      paddingLeft: '10px',
      '& svg': {
        opacity: 0,
      },
    },
    iconColor: {
      fill: '#000000',
    },
    logoutIconColor: {
      fill: '#ffffff',
    },
  },
  logoutListItem: {
    backgroundColor: '#083D49',
    textTransform: 'uppercase',
    color: 'white',
    pl: '16px',
    pt: '20px',
    pb: '20px',
    '& div': {
      margin: 0,
    },
    '& svg': {
      transition: 'all 0.3s',
      fill: '#ffffff',
    },
    '&:hover': {
      fontWeight: 'bold',
      color: '#3e3f60',
      borderLeft: '6px solid #40DED4',
      backgroundColor: '#ffffff',
      paddingLeft: '10px',
      '& svg': {
        opacity: 0,
      },
    },
  },
  itemIcons: {
    minWidth: 0,
  },
};

const Header: React.FC<{ children: React.ReactElement }> = ({ children }): React.ReactElement => {
  const user: UserType = JSON.parse(localStorage.getItem('user') || '{}');
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutUser = () => {
    dispatch(logoutUser()).then(() => {
      handleClose();
    });
  };

  useEffect(() => {
    if (user?.is_super_admin) {
      pusher
        .subscribe('artwork_version')
        .bind('pages_made', (data: { allowed_users_ids: any[]; brand_request_id: number }) => {
          if (data?.allowed_users_ids.includes(user?.id)) {
            dispatch(
              setSuccessToastState(
                true,
                'Artwork Pages Generated Succesully',
                data?.brand_request_id,
                true,
              ),
            );
          }
        });
      pusher
        .subscribe('client_artwork_version')
        .bind('pages_made', (data: { allowed_users_ids: any[]; brand_request_id: number }) => {
          if (data?.allowed_users_ids.includes(user?.id)) {
            dispatch(
              setSuccessToastState(
                true,
                'Artwork Pages Generated Succesully',
                data?.brand_request_id,
              ),
            );
          }
        });
    } else {
      pusher
        .subscribe('client_artwork_version')
        .bind('pages_made', (data: { allowed_users_ids: any[]; brand_request_id: number }) => {
          if (data?.allowed_users_ids.includes(user?.id)) {
            dispatch(
              setSuccessToastState(
                true,
                'Artwork Pages Generated Succesully',
                data?.brand_request_id,
              ),
            );
          }
        });
    }
  }, [dispatch, user]);

  useEffect(() => {
    dispatch(set_user(user));
  }, [user, dispatch]);

  return user.id ? (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        variant="permanent"
        anchor="left"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            boxShadow: '10px 0px 10px #0000001A',
          },
        }}
        PaperProps={{
          style: {
            backgroundColor: '#011E41',
            borderRight: 0,
          },
        }}>
        <Stack
          justifyContent="space-between"
          sx={{
            height: '100%',
            minHeight: 700,
          }}>
          <List>
            <ListItem
              sx={{ mb: '30px', justifyContent: 'center' }}
              component={Link}
              to="/new-brief">
              <img style={{ height: 140 }} alt="FA" src="/images/logo.svg" />
            </ListItem>
            <ListItem sx={{ borderBottom: '1px solid rgba(255, 255, 255, 0.2)' }}>
              <Typography sx={classes.sectionTitle}>Creative Approval</Typography>
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/live-projects"
              sx={
                location.pathname.startsWith('/live-projects') ? classes.selectedNav : classes.link
              }>
              <ListItemIcon sx={classes.itemIcons}>
                {/* <SvgIcon component={Outline} /> */}
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: '14px',
                  marginLeft: '15px',
                }}
                primary="Live projects"
              />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/quote-requests"
              sx={
                location.pathname.startsWith('/quote-requests') ? classes.selectedNav : classes.link
              }
              alignItems="center">
              <ListItemIcon sx={classes.itemIcons}>
                {/* <SvgIcon component={Quote} sx={classes.icons} /> */}
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: '14px',
                  marginLeft: '15px',
                }}
                primary="Quote requests"
              />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/new-brief"
              sx={location.pathname.startsWith('/new-brief') ? classes.selectedNav : classes.link}>
              <ListItemIcon sx={classes.itemIcons}></ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ fontSize: '14px', marginLeft: '15px' }}
                primary="New brief"
              />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/archive"
              sx={location.pathname.startsWith('/archive') ? classes.selectedNav : classes.link}>
              <ListItemIcon sx={classes.itemIcons}></ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ fontSize: '14px', marginLeft: '15px' }}
                primary="Archive"
              />
            </ListItem>

            {user?.is_super_admin && (
              <ListItem
                button
                component={Link}
                to="/admin"
                sx={location.pathname.startsWith('/admin') ? classes.selectedNav : classes.link}>
                <ListItemIcon sx={classes.itemIcons}></ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: '14px', marginLeft: '15px' }}
                  primary="Admin"
                />
              </ListItem>
            )}
          </List>

          <List sx={{ pb: 0 }}>
            <Grid container justifyContent="center">
              <Grid item>
                <Typography
                  sx={{
                    color: '#fff',
                    fontFamily: 'FS Jack Italic',
                    fontSize: '12px',
                  }}>
                  Powered by
                </Typography>
              </Grid>
              <Grid item>
                <img
                  style={{ height: '18px', marginLeft: '10px' }}
                  alt="Bylder Logo"
                  src="/images/logo/bylder-logo.svg"
                />
              </Grid>
            </Grid>
          </List>
        </Stack>
      </Drawer>
      <Box component="main" sx={classes.content}>
        <Grid container direction="column" justifyContent="center">
          <Grid item sx={{ borderBottom: '0.5px solid #3c3c3c' }}>
            <Grid container direction="row" sx={classes.topContainer}>
              <Grid item>
                <Grid container direction="row" spacing={2} alignItems="center">
                  <Button
                    onClick={handleClick}
                    sx={{
                      color: '#333A40',
                      fontSize: '14px',
                      textTransform: 'capitalize',
                    }}
                    startIcon={<AccountCircleOutlined />}>
                    {user?.name}
                  </Button>
                  <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                    <MenuItem
                      onClick={() => {
                        handleLogoutUser();
                      }}>
                      Logout
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>{children}</Grid>
        </Grid>
      </Box>
    </Box>
  ) : (
    <>{children}</>
  );
};

export default Header;

import { createTheme } from '@mui/material/styles';
const FSJackBold = require('./css/Fonts/FS-Jack-Bold.otf');
const FSJackBoldItalic = require('./css/Fonts/FS-Jack-Bold-Italic.otf');
const FSJackLight = require('./css/Fonts/FS-Jack-Light.otf');
const FSJackLightItalic = require('./css/Fonts/FS-Jack-Light-Italic.otf');
const FSJackMedium = require('./css/Fonts/FS-Jack-Medium.otf');
const FSJackMediumItalic = require('./css/Fonts/FS-Jack-Medium-Italic.otf');
const FSJackPoster = require('./css/Fonts/FS-Jack-Poster.otf');

export const Theme = createTheme({
  palette: {
    primary: {
      main: '#DEE2E6',
    },
    secondary: {
      main: '#FA5519',
    },
    error: {
      main: '#fa2113',
    },
  },
  typography: {
    fontFamily: [
      'FS Jack',
      'FS Jack Light',
      'FS Jack Bold',
      'FS Jack Italic',
      'FS Jack Light Italic',
      'FS Jack Bold Italic',
      'FS Jack Poster',
    ].join(','),
    h1: {
      fontFamily: 'FS Jack Bold',
      fontSize: 56,
      color: '#083D49',
      lineHeight: '65px',
      textTransform: 'uppercase',
    },
    h2: {
      fontFamily: 'FS Jack Bold',
      fontSize: 42,
      lineHeight: '49px',
    },
    h3: {
      fontFamily: 'FS Jack Bold',
      fontSize: 30,
      lineHeight: '35px',
    },
    h4: {
      fontFamily: 'FS Jack Bold',
      fontSize: 20,
      lineHeight: '23px',
    },
    h5: {
      fontFamily: 'FS Jack Bold',
      fontSize: 18,
      lineHeight: '21px',
    },
    h6: {
      fontFamily: 'FS Jack',
      fontSize: 14,
      lineHeight: '24px',
      color: '#000000',
    },
    subtitle1: {
      fontFamily: 'FS Jack Bold',
      fontSize: '16px',
      lineHeight: '18px',
      color: '#083D49',
      textTransform: 'uppercase',
    },
    subtitle2: {
      fontFamily: 'FS Jack Bold',
      fontSize: 14,
    },
    body1: {
      fontFamily: 'FS Jack',
      fontSize: 16,
      lineHeight: '21px',
    },
    body2: {
      fontFamily: 'FS Jack',
      fontSize: 12,
      lineHeight: '16px',
    },
    button: {},
    caption: {
      fontFamily: 'FS Jack Bold',
    },
    overline: {},
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          marginRight: 2,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        body {
          margin: 0;
          padding: 0;
          width: 100%;
          height: 100%;
          background-color: #ffffff !important;
        }
  
        @font-face{
          font-family:"FS Jack Light";
          src:url(${FSJackLight}) format("opentype");
        }
        
        @font-face{
          font-family:"FS Jack Light Italic";
          src:url(${FSJackLightItalic}) format("opentype");
        }
  
       @font-face{
          font-family:"FS Jack";
          src:url(${FSJackMedium}) format("opentype");
        }
        
        @font-face{
          font-family:"FS Jack Italic";
          src:url(${FSJackMediumItalic}) format("opentype");
        }
        
        @font-face{
          font-family:"FS Jack Bold";
          src:url(${FSJackBold}) format("opentype");
        }
        
        @font-face{
          font-family:"FS Jack Bold Italic";
          src:url(${FSJackBoldItalic}) format("opentype");
        }
        
        @font-face{
          font-family:"FS Jack Poster";
          src:url(${FSJackPoster}) format("opentype");
        }
      `,
    },
    MuiTextField: {
      defaultProps: {
        style: {
          minHeight: 40,
          backgroundColor: 'white',
          justifyContent: 'center',
          padding: '3px',
        },
        InputProps: {
          disableUnderline: true,
        },
      },
    },
    MuiDrawer: {
      defaultProps: {
        PaperProps: {
          style: {
            padding: 20,
            minWidth: 400,
            width: 400,
            height: '100%',
            backgroundColor: '#f8f9fa',
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        style: {
          backgroundColor: 'white',
          padding: '3px',
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            background: '#011E41',
            borderRadius: 0,
            boxShadow: 'none',
            fontSize: 12,
            fontWeight: 'bold',
            height: 36,
            color: '#fff',
            paddingLeft: '40px',
            paddingRight: '40px',
            '&:hover': {
              background: '#DEE2E6',
              color: '#011E41',
            },
          },
        },
        {
          props: { variant: 'text' },
          style: {
            textTransform: 'uppercase',
            fontSize: 16,
            fontFamily: 'FS Jack',
            borderRadius: '2px',
            color: '#222528',
            height: 36,
            '&:hover': {
              background: '#DEE2E6',
              color: '#011E41',
              borderRadius: '4px',
            },
          },
        },
      ],
    },
    MuiTab: {
      defaultProps: {
        style: {
          color: 'black',
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        indicatorColor: 'secondary',
      },
    },
  },
});

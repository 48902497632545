import React, { useState } from 'react';
import { Grid, Dialog, DialogContent, DialogContentText, Button, Typography } from '@mui/material';
import { deleteUser, fetchUserList } from './actions';
import { useDispatch, useSelector } from 'react-redux';
import { Watch } from 'react-loader-spinner';
import { LoadingButton } from '@mui/lab';
import { setErrorToastState } from 'Store/action';
import { set_delete_user_dialog, set_user_details_drawer } from 'Store/reducers/adminReducer';
import { set_users, set_user_details } from 'Store/reducers/userReducer';
import { AppDispatch, RootState } from 'Store/Store';

export const DeleteUser: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch<AppDispatch>();
  const delete_user_dialog = useSelector((state: RootState) => state?.admin?.delete_user_dialog);
  const user_details = useSelector((state: RootState) => state?.user?.user_details);
  const [loading, setLoading] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('');

  const handleDeleteUser = (confirm: boolean) => {
    setServerErrorMessage('');
    if (confirm) {
      setLoading(true);
      return deleteUser(user_details?.id || '')
        .then(res => {
          if (res.status === 200) {
            fetchUserList().then(res => {
              if (res.status === 200) {
                dispatch(set_users(res.data));
              }
            });
            dispatch(set_user_details(null));
            dispatch(set_delete_user_dialog(false));
            dispatch(set_user_details_drawer(false));
          }
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          dispatch(setErrorToastState(true, 'Something went wrong, please try again in a while.'));
        });
    } else {
      dispatch(set_delete_user_dialog(false));
    }
  };

  return (
    <Dialog
      PaperProps={{ style: { minWidth: 390, borderRadius: 0, padding: 20 } }}
      open={delete_user_dialog}
      keepMounted>
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h5">Delete User?</Typography>
          </Grid>
          <Grid item>
            <DialogContentText>
              Are you sure you want to delete this user? This action will not be reversible.
            </DialogContentText>
          </Grid>
          {serverErrorMessage && (
            <Grid item>
              <Typography color="error" textAlign="center">
                {serverErrorMessage}
              </Typography>
            </Grid>
          )}
          <Grid item>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                <LoadingButton
                  onClick={() => handleDeleteUser(true)}
                  loadingIndicator={<Watch color="#063e84" width={30} height={20} />}
                  loading={loading}
                  variant="contained"
                  fullWidth>
                  Yes, delete
                </LoadingButton>
              </Grid>
              <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                <Button
                  fullWidth
                  variant="contained"
                  disabled={loading}
                  onClick={() => handleDeleteUser(false)}
                  sx={{
                    backgroundColor: '#ECEDF3',
                    boxShadow: 'none',
                    color: '#333F48',
                    textTransform: 'initial',
                    '&:hover': {
                      backgroundColor: '#ECEDF3',
                      boxShadow: 'none',
                      color: '#333F48',
                      textTransform: 'initial',
                      textDecoration: 'underline',
                      textDecorationThickness: '2px',
                      textDecorationColor: '#333F48',
                    },
                  }}>
                  No, cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteUser;

import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from '../Permissions/ProtectedRoute';
import SuspenseContainer from '../Components/Suspense/SuspenseContainer';
import Admin from '../pages/Admin/Admin';
import AdminProtectedRoute from '../Permissions/AdminProtectedRoute';

const NewBrief = lazy(() => import('../pages/NewBrief/NewBrief'));
const Submit = lazy(() => import('../pages/NewBrief/Submit/Submit'));
const QuoteRequests = lazy(() => import('../pages/QuoteRequests/QuoteRequests'));
const LiveProjects = lazy(() => import('../pages/LiveProjects/LiveProjects'));
const ProjectQuoteRequests = lazy(() =>
  import('../pages/QuoteRequests/QuoteRequestsDetails/QuoteRequestsDetails'),
);
const LiveProjectRequestReview = lazy(() =>
  import('../pages/LiveProjects/LiveProjectRequestReview/LiveProjectRequestReview'),
);
const EditBrief = lazy(() =>
  import('../pages/QuoteRequests/QuoteRequestsDetails/Components/EditBrief/EditBrief'),
);
const ArchiveProjectRequests = lazy(() => import('../pages/Archive/ArchiveProjectRequests'));
const ArchiveRequestDetails = lazy(() =>
  import('../pages/Archive/ArchiveRequestDetails/ArchiveRequestDetails'),
);
const NoMatch = lazy(() => import('Components/NoMatch/NoMatch'));
const NewPassword = lazy(() => import('../UserAuthentication/Components/NewPassword/NewPassword'));
const ResetPassword = lazy(() => import('../UserAuthentication/Components/ResetPassword/ResetPassword'));
const Login = lazy(() => import('../UserAuthentication/Components/Login/Login'));

const AllRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <SuspenseContainer>
            <Login />
          </SuspenseContainer>
        }
      />
      <Route
        path="/live-projects"
        element={
          <ProtectedRoute>
            <SuspenseContainer>
              <LiveProjects />
            </SuspenseContainer>
          </ProtectedRoute>
        }
      />
      <Route
        path="/live-projects/:id/*"
        element={
          <ProtectedRoute>
            <SuspenseContainer>
              <LiveProjectRequestReview />
            </SuspenseContainer>
          </ProtectedRoute>
        }
      />
      <Route
        path="/quote-requests"
        element={
          <ProtectedRoute>
            <SuspenseContainer>
              <QuoteRequests />
            </SuspenseContainer>
          </ProtectedRoute>
        }
      />
      <Route
        path="/quote-requests/:id"
        element={
          <ProtectedRoute>
            <SuspenseContainer>
              <ProjectQuoteRequests />
            </SuspenseContainer>
          </ProtectedRoute>
        }
      />
      <Route
        path="/quote-requests/:id/edit"
        element={
          <ProtectedRoute>
            <SuspenseContainer>
              <EditBrief />
            </SuspenseContainer>
          </ProtectedRoute>
        }
      />
      <Route
        path="/new-brief"
        element={
          <ProtectedRoute>
            <SuspenseContainer>
              <NewBrief />
            </SuspenseContainer>
          </ProtectedRoute>
        }
      />
      <Route
        path="/new-brief/:id/submit"
        element={
          <ProtectedRoute>
            <SuspenseContainer>
              <Submit />
            </SuspenseContainer>
          </ProtectedRoute>
        }
      />
      <Route
        path="/archive"
        element={
          <ProtectedRoute>
            <SuspenseContainer>
              <ArchiveProjectRequests />
            </SuspenseContainer>
          </ProtectedRoute>
        }
      />
      <Route
        path="/archive/:id"
        element={
          <ProtectedRoute>
            <SuspenseContainer>
              <ArchiveRequestDetails />
            </SuspenseContainer>
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin"
        element={
          <AdminProtectedRoute>
            <SuspenseContainer>
              <Admin />
            </SuspenseContainer>
          </AdminProtectedRoute>
        }
      />
      <Route
        path="/new-password/:token"
        element={
          <SuspenseContainer>
            <NewPassword />
          </SuspenseContainer>
        }
      />
      <Route
        path="/reset"
        element={
          <SuspenseContainer>
            <ResetPassword />
          </SuspenseContainer>
        }
      />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};

export default AllRoutes;

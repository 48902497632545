import React from 'react';
import { Button, Grid, Drawer, IconButton, Typography } from '@mui/material';
// Styles
import { Close, Delete, Edit } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import {
  set_delete_user_dialog,
  set_edit_user_drawer,
  set_user_details_drawer,
} from 'Store/reducers/adminReducer';
import { AppDispatch, RootState } from 'Store/Store';

export const UserDetails: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch<AppDispatch>();
  const user_details_drawer = useSelector(({ admin }: RootState) => admin.user_details_drawer);
  const user_details = useSelector(({ user }: RootState) => user.user_details);

  return (
    <Drawer
      BackdropProps={{ invisible: true }}
      anchor="right"
      open={user_details_drawer}
      sx={{ zIndex: theme => theme.zIndex.drawer + 2 }}
      PaperProps={{
        sx: {
          padding: '20px',
          minWidth: 400,
          height: '100%',
        },
      }}>
      <Grid
        container
        direction="column"
        flexWrap="nowrap"
        justifyContent="space-between"
        sx={{ height: '100%' }}>
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                <IconButton
                  onClick={() => {
                    dispatch(set_user_details_drawer(false));
                  }}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="column" justifyContent="space-between">
                <Grid item>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Typography variant="subtitle1">User ID: {user_details?.id}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="h5">{user_details?.name}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="h6" color="secondary" fontWeight={700}>
                        Contact Details
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={2} direction="row">
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                          <Typography>Email:</Typography>
                        </Grid>
                        <Grid item xs sm md lg xl>
                          <Typography>{user_details?.email}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={2} direction="row">
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                          <Typography>Phone:</Typography>
                        </Grid>
                        <Grid item xs sm md lg xl>
                          <Typography>{user_details?.phone}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid container spacing={2} direction="row">
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                          <Typography>Role:</Typography>
                        </Grid>
                        <Grid item xs sm md lg xl>
                          <Typography>
                            {user_details?.role ? user_details?.role.display_name : ''}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Grid item>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Button
                      variant="contained"
                      startIcon={<Delete />}
                      sx={{
                        backgroundColor: '#ECEDF3',
                        color: '#333F48',
                        textTransform: 'initial',
                        '&:hover': {
                          backgroundColor: '#ECEDF3',
                          color: '#333F48',
                          textTransform: 'initial',
                          textDecoration: 'underline',
                          textDecorationThickness: '2px',
                          textDecorationColor: '#333F48',
                        },
                      }}
                      onClick={() => dispatch(set_delete_user_dialog(true))}
                      fullWidth>
                      Delete User
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      fullWidth
                      startIcon={<Edit />}
                      variant="contained"
                      onClick={() => dispatch(set_edit_user_drawer(true))}>
                      Edit User
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default UserDetails;

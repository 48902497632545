import React from "react";
// Types
import { Grid, MenuItem, TextField, Select, Typography } from "@mui/material";
import __ from "lodash";
import { TableFileterTypes } from "./types/table-filter.types";
import { TableFilterObjectTypes } from "shared/types/TableFilterObject.types";

export const TableFilter: React.FC<TableFileterTypes> = ({ title, counter, filters, setFilters }: TableFileterTypes) => {
  const inputFilter = __.debounce((name, filterValue) => {
    setFilters((prevState: TableFilterObjectTypes) => ({
      ...prevState,
      [name]: filterValue,
    }));
  }, 300);

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item>
        <Typography variant="h6">
          <>
            {title} <span>({counter})</span>
          </>
        </Typography>
      </Grid>
      <Grid item>
        <Grid container direction="row" spacing={2} alignItems="center">
          <Grid item>
            <TextField
              variant="standard"
              name="search"
              placeholder="Search"
              onChange={(e) => inputFilter("search", e.target.value)}
            />
          </Grid>
          {/* <Grid item>
            <Grid container direction="row" alignItems="center" spacing={2}>
              <Grid item>
                <Typography variant="body2">SORT:</Typography>
              </Grid>
              <Grid item>
                <Select
                  name="sort"
                  variant="standard"
                  disableUnderline
                  value={filters.sort}
                  placeholder="Sort"
                  onChange={(e) => inputFilter("sort", e.target.value)}
                >
                  <MenuItem value="name">Name</MenuItem>
                  <MenuItem value="type">Type</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Grid> */}
          <Grid item>
            <Grid container direction="row" alignItems="center" spacing={2}>
              <Grid item>
                <Typography variant="body2">SHOW:</Typography>
              </Grid>
              <Grid item>
                <Select
                  name="perPage"
                  variant="standard"
                  disableUnderline
                  value={filters.perPage}
                  placeholder="Per Page"
                  onChange={(e) => inputFilter("perPage", e.target.value)}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TableFilter;

import { AxiosPromise } from 'axios';
import api from 'helpers/axios-interceptor/api';

export const fetchUserList = (): AxiosPromise => {
  return api.get(`/users`);
};

export const checkExistingEmail = (email: string): AxiosPromise => {
  return api.get(`/users?email=${email}`);
};

export const createUser = (data: any): AxiosPromise => {
  return api.post(`/users`, data);
};

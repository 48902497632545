import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Add } from '@mui/icons-material';
import { Box, Button, Grid, Tab, Tabs, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import UserTable from './user-table/user-table.component';
import CreateUser from './user/create-user/create-user.component';
import DeleteUser from './user/delete-user/delete-user.component';
import EditUser from './user/edit-user/edit-user.component';
import UserDetails from './user/user-details/user-details.component';
import { set_create_user_drawer } from 'Store/reducers/adminReducer';

interface TabPanelProps {
  children: React.ReactElement;
  index?: number;
  value?: number;
}

const Admin: React.FC = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const params = useParams();
  const [selectedTab, setSelectedTab] = useState(0);
  const { tab } = params;

  useEffect(() => {
    if (tab && selectedTab === null) {
      setSelectedTab(Number(tab));
    }
  }, [tab, selectedTab]);

  useEffect(() => {
    if (selectedTab) {
      router(`/admin?tab=${selectedTab}`);
    }
  }, [selectedTab, router]);

  const TabPanel: React.FC<TabPanelProps> = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <div hidden={value !== index} {...other}>
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  };

  return (
    <>
      <Grid container direction="column" style={{ padding: 30 }}>
        <Grid item>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Typography variant="h2" fontWeight={700}>
                Admin
              </Typography>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <Button
                    startIcon={<Add />}
                    variant="contained"
                    onClick={() => {
                      dispatch(set_create_user_drawer(true));
                    }}>
                    Add User
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={selectedTab}
              onChange={(e: SyntheticEvent, value: number) => setSelectedTab(value)}>
              <Tab label="Users" />
            </Tabs>
          </Box>
          <TabPanel>
            <UserTable />
          </TabPanel>
        </Grid>
      </Grid>
      <CreateUser />
      <EditUser />
      <DeleteUser />
      <UserDetails />
    </>
  );
};

export default Admin;

import { AxiosPromise } from 'axios';
import api from 'helpers/axios-interceptor/api';

export const fetchUserList = (): AxiosPromise => {
  return api.get(`/users`);
};

export const updateUserAction = (id: string, data: any): AxiosPromise => {
  return api.put(`/users/${id}`, data);
};

import api from 'helpers/axios-interceptor/api';
import {
  set_client_artwork_versions,
  set_client_comments,
  set_client_decision_mode,
  set_client_reviewers,
  set_project_data_client,
} from 'Store/reducers/projectReducer';
import { set_artwork_versions } from 'Store/reducers/proofingReducer';
import { setErrorToastState } from 'Store/action';
import { AxiosPromise } from 'axios';
import { AppDispatch } from 'Store/Store';

export const uploadArtworkClientReview = (data: any): AxiosPromise => {
  return api.post('/brand-request-client-artwork-versions', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getClientReviewers = (search: string, request_id: string): AxiosPromise => {
  return api.get(`/users?role=client&search=${search}&brand_request=${request_id}`);
};

export const addClientComments = (id: string, data: any): AxiosPromise => {
  return api.post(`/brand-request/${id}/client-review/comments`, data);
};

export const addClientReviewer = (data: any): AxiosPromise => {
  return api.post(`/brand-request-artwork-versions/client-review/reviewers`, data);
};

export const removeClientReviewer = (data: any): AxiosPromise => {
  return api.post(`/brand-request-artwork-versions/client-review/reviewers?_method=delete`, data);
};

export const setReviewersDecisionAccept = (brand_request_id: string) => (dispatch: AppDispatch) =>
  api.post(`/brand-request/${brand_request_id}/client-review/accept`).then(res => {
    if (res.status === 200) {
      dispatch(handleGetClientProjectData(brand_request_id));
    }
  });

export const setReviewersDecisionDecline = (brand_request_id: string) => (dispatch: AppDispatch) =>
  api.post(`/brand-request/${brand_request_id}/client-review/decline`).then(res => {
    if (res.status === 200) {
      dispatch(handleGetClientProjectData(brand_request_id));
    }
  });

export const handleSetArtworkVersions = (versions: any) => (dispatch: AppDispatch) =>
  dispatch(set_artwork_versions(versions));

export const handleGetClientProjectData = (id: string) => (dispatch: AppDispatch) => {
  fetchClientProjectData(id)
    .then(res => {
      if (res.status === 200) {
        dispatch(set_project_data_client(res.data.data));
        dispatch(set_artwork_versions(res.data.data.clientArtworkVersions));
        dispatch(set_client_artwork_versions(res.data.data.clientArtworkVersions));
        dispatch(set_client_comments(res.data.data.clientComments));
        dispatch(set_client_reviewers(res.data.data.artworkClientReviewers));
        dispatch(set_client_decision_mode(res.data.data.client_review_pass_one_required));
      }
    })
    .catch(err => {
      dispatch(setErrorToastState(true, 'Something went wrong, please try again in a while.'));
    });
};

export const fetchClientProjectData = (id: string): AxiosPromise => {
  return api.get(`/brand-request/${id}/client`);
};

export const getClientComments = (id: string): AxiosPromise => {
  return api.get(`/brand-request/${id}/client`);
};

export const updateClientReviewerDecisionMode = (id: string, state: boolean): AxiosPromise => {
  return api.post(`/brand-request/${id}/client-review-pass-mode`, {
    client_review_pass_one_required: state,
  });
};

import React, { useState } from 'react';
import {
  Button,
  Grid,
  Drawer,
  Typography,
  Divider,
  TextField,
  Select,
  MenuItem,
  IconButton,
} from '@mui/material';
// Styles
import { Close } from '@mui/icons-material';
import { Field, Form, Formik, ErrorMessage, FieldProps } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserList, updateUserAction } from './actions';
import { LoadingButton } from '@mui/lab';
import { Watch } from 'react-loader-spinner';
import * as Yup from 'yup';
import { setErrorToastState } from 'Store/action';
import { set_edit_user_drawer } from 'Store/reducers/adminReducer';
import { set_users, set_user_details } from 'Store/reducers/userReducer';
import { AppDispatch, RootState } from 'Store/Store';

export const EditUser: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch<AppDispatch>();
  const edit_user_drawer = useSelector((state: RootState) => state?.admin?.edit_user_drawer);
  const user_details = useSelector((state: RootState) => state?.user?.user_details);
  const [loading, setLoading] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('');

  const updateUser = (data: any) => {
    setServerErrorMessage('');
    setLoading(true);

    if (user_details) {
      updateUserAction(user_details?.id, data)
        .then(res => {
          if (res.status === 200) {
            fetchUserList().then(res => {
              if (res.status === 200) {
                dispatch(set_users(res.data));
              }
            });
            dispatch(set_edit_user_drawer(false));
            dispatch(set_user_details(res.data));
          }
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          dispatch(setErrorToastState(true, 'Something went wrong, please try again in a while.'));
        });
    }
  };

  return (
    <Drawer
      BackdropProps={{ invisible: true }}
      anchor="right"
      open={edit_user_drawer}
      sx={{ zIndex: theme => theme.zIndex.drawer + 2 }}>
      <Formik
        validationSchema={Yup.object().shape({
          first_name: Yup.string().max(255).required('Required!'),
          last_name: Yup.string().max(255).required('Required!'),
          email: Yup.string().max(255).email().required('Required!'),
          phone: Yup.string().max(255),
          password: Yup.string().matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            'Password must contain at least 8 characters, one uppercase, one number and one special case character',
          ),
          role: Yup.string().min(1).required('Required!'),
        })}
        onSubmit={updateUser}
        enableReinitialize
        initialValues={{
          first_name: user_details?.first_name || '',
          last_name: user_details?.last_name || '',
          email: user_details?.email || '',
          phone: user_details?.phone || '',
          password: user_details?.password || '',
          role: user_details?.role?.name || '',
        }}>
        {({ touched, errors, handleSubmit }) => (
          <Form style={{ height: '100%' }}>
            <Grid
              container
              direction="column"
              flexWrap="nowrap"
              justifyContent="space-between"
              style={{ height: '100%' }}>
              <Grid item>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center">
                      <Grid item>
                        <Typography variant="h5">Update User</Typography>
                      </Grid>
                      <Grid item>
                        <IconButton
                          disabled={loading}
                          onClick={() => dispatch(set_edit_user_drawer(false))}>
                          <Close />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6">User account</Typography>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Grid container direction="column">
                          <Grid item>
                            <Typography>First name</Typography>
                          </Grid>
                          <Grid item>
                            <Field name="first_name">
                              {({ field }: FieldProps) => (
                                <TextField fullWidth {...field} variant="standard" margin="dense" />
                              )}
                            </Field>
                          </Grid>
                          <Grid item>
                            <ErrorMessage name="first_name">
                              {message => <Typography color="error">{message}</Typography>}
                            </ErrorMessage>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Grid container direction="column">
                          <Grid item>
                            <Typography>Last name</Typography>
                          </Grid>
                          <Grid item>
                            <Field name="last_name">
                              {({ field }: FieldProps) => (
                                <TextField fullWidth {...field} variant="standard" margin="dense" />
                              )}
                            </Field>
                          </Grid>
                          <Grid item>
                            <ErrorMessage name="last_name">
                              {message => <Typography color="error">{message}</Typography>}
                            </ErrorMessage>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography>Email</Typography>
                      </Grid>
                      <Grid item>
                        <Field name="email">
                          {({ field }: FieldProps) => (
                            <TextField fullWidth {...field} variant="standard" margin="dense" />
                          )}
                        </Field>
                      </Grid>
                      <Grid item>
                        <ErrorMessage name="email">
                          {message => <Typography color="error">{message}</Typography>}
                        </ErrorMessage>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography>Phone Number</Typography>
                      </Grid>
                      <Grid item>
                        <Field name="phone">
                          {({ field }: FieldProps) => (
                            <TextField
                              fullWidth
                              placeholder="+44 (0) 7123 456 789"
                              {...field}
                              variant="standard"
                              margin="dense"
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item>
                        <ErrorMessage name="phone">
                          {message => <Typography color="error">{message}</Typography>}
                        </ErrorMessage>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography>Password</Typography>
                      </Grid>
                      <Grid item>
                        <Field name="password">
                          {({ field }: FieldProps) => (
                            <TextField
                              fullWidth
                              {...field}
                              type="password"
                              variant="standard"
                              margin="dense"
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item>
                        <ErrorMessage name="password">
                          {message => <Typography color="error">{message}</Typography>}
                        </ErrorMessage>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Divider />
                  </Grid>
                  <Grid item>
                    <Typography variant="h6">User account</Typography>
                  </Grid>
                  <Grid item>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography>Role</Typography>
                      </Grid>
                      <Grid item>
                        <Field name="role">
                          {({ field }: FieldProps) => (
                            <Select
                              disableUnderline
                              {...field}
                              fullWidth
                              value={field.value}
                              variant="standard"
                              margin="dense"
                              error={touched.role && !!errors.role ? true : false}>
                              <MenuItem value="" selected disabled>
                                Select Role
                              </MenuItem>
                              <MenuItem value="super_admin">Super Admin</MenuItem>
                              <MenuItem value="client">Client</MenuItem>
                            </Select>
                          )}
                        </Field>
                      </Grid>
                      <Grid item>
                        <ErrorMessage name="role">
                          {message => <Typography color="error">{message}</Typography>}
                        </ErrorMessage>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="column" spacing={2}>
                  {serverErrorMessage && (
                    <Grid item>
                      <Typography color="error" textAlign="center">
                        {serverErrorMessage}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item>
                    <LoadingButton
                      onClick={() => handleSubmit()}
                      loadingIndicator={<Watch color="#063e84" width={30} height={20} />}
                      loading={loading}
                      variant="contained"
                      fullWidth>
                      Update User
                    </LoadingButton>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      disabled={loading}
                      sx={{
                        backgroundColor: '#ECEDF3',
                        boxShadow: 'none',
                        color: '#333F48',
                        textTransform: 'initial',
                        '&:hover': {
                          backgroundColor: '#ECEDF3',
                          boxShadow: 'none',
                          color: '#333F48',
                          textTransform: 'initial',
                          textDecoration: 'underline',
                          textDecorationThickness: '2px',
                          textDecorationColor: '#333F48',
                        },
                      }}
                      onClick={() => dispatch(set_edit_user_drawer(false))}
                      fullWidth>
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};

export default EditUser;

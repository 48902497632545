import React, { useState } from 'react';
import {
  Button,
  Grid,
  Drawer,
  Typography,
  TextField,
  IconButton,
  MenuItem,
  Select,
  Divider,
} from '@mui/material';
// Styles
import { Close } from '@mui/icons-material';
import { ErrorMessage, Field, FieldProps, Form, Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { checkExistingEmail, createUser, fetchUserList } from './actions';
import { Watch } from 'react-loader-spinner';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import __ from 'lodash';
import { setErrorToastState } from 'Store/action';
import { set_create_user_drawer } from 'Store/reducers/adminReducer';
import { set_users } from 'Store/reducers/userReducer';
import { AppDispatch, RootState } from 'Store/Store';

export const CreateUser: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch<AppDispatch>();
  const create_user_drawer = useSelector((state: RootState) => state?.admin?.create_user_drawer);
  const [emailUnique, setEmailUnique] = useState([]);
  const [loading, setLoading] = useState(false);

  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    password: '',
    role: '',
  };

  const RequestSchema = Yup.object().shape({
    first_name: Yup.string().max(300).required('Required'),
    last_name: Yup.string().max(300).required('Required'),
    email: Yup.string().max(300).email().required('Required'),
    phone: Yup.string().max(255),
    password: Yup.string()
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Password must contain at least 8 characters, one uppercase, one number and one special case character',
      )
      .required('Required!'),
    role: Yup.string().min(1).required('Required!'),
  });

  const handleSubmit = (data: any) => {
    setLoading(true);
    createUser(data)
      .then(res => {
        if (res.status === 200) {
          fetchUserList().then(res => {
            if (res.status === 200) {
              dispatch(set_users(res.data));
            }
          });
          dispatch(set_create_user_drawer(false));
        }
        setLoading(false);
      })
      .catch(err => {
        dispatch(setErrorToastState(true, 'Something went wrong, please try again in a while.'));
        setLoading(false);
      });
  };

  const inputFilter = __.debounce(email => {
    setEmailUnique([]);
    if (email) {
      checkExistingEmail(email).then(res => {
        setEmailUnique(res.data.data);
      });
    }
  }, 300);

  return (
    <Drawer
      BackdropProps={{ invisible: true }}
      anchor="right"
      open={create_user_drawer}
      sx={{ zIndex: theme => theme.zIndex.drawer + 2 }}>
      <Formik
        validationSchema={RequestSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        initialValues={initialValues}>
        {({ values, touched, errors, handleSubmit }) => (
          <Form style={{ height: '100%' }}>
            <Grid
              container
              flexWrap="nowrap"
              direction="column"
              justifyContent="space-between"
              sx={{
                height: '100%',
              }}>
              <Grid item>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center">
                      <Grid item>
                        <Typography variant="h5">Create new user</Typography>
                      </Grid>
                      <Grid item>
                        <IconButton
                          disabled={loading}
                          onClick={() => dispatch(set_create_user_drawer(false))}>
                          <Close />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6">User account</Typography>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={2}>
                      <Grid item md>
                        <Grid container direction="column">
                          <Grid item>
                            <Typography>First name</Typography>
                          </Grid>
                          <Grid item>
                            <Field name="first_name">
                              {({ field }: FieldProps) => (
                                <TextField
                                  variant="standard"
                                  margin="dense"
                                  {...field}
                                  fullWidth
                                  placeholder="First Name"
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid item>
                            <ErrorMessage name="first_name">
                              {message => <Typography color="error">{message}</Typography>}
                            </ErrorMessage>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item md>
                        <Grid container direction="column">
                          <Grid item>
                            <Typography>Last name</Typography>
                          </Grid>
                          <Grid item>
                            <Field name="last_name">
                              {({ field }: FieldProps) => (
                                <TextField
                                  variant="standard"
                                  margin="dense"
                                  {...field}
                                  fullWidth
                                  placeholder="Last Name"
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid item>
                            <ErrorMessage name="last_name">
                              {message => <Typography color="error">{message}</Typography>}
                            </ErrorMessage>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography>Email</Typography>
                      </Grid>
                      <Grid item>
                        <Field name="email">
                          {({ field }: FieldProps) => (
                            <TextField
                              variant="standard"
                              margin="dense"
                              {...field}
                              fullWidth
                              onBlur={event => {
                                inputFilter(event.target.value);
                              }}
                              placeholder="name.surname@example.com"
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center">
                          <Grid item>
                            {touched.email ? (
                              <Typography style={{ color: 'red' }}>{errors.email}</Typography>
                            ) : null}
                          </Grid>
                          <Grid item>
                            {emailUnique.length > 0 ? (
                              <Typography style={{ color: 'red' }}>Email already taken</Typography>
                            ) : null}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <ErrorMessage name="email">
                          {message => <Typography color="error">{message}</Typography>}
                        </ErrorMessage>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography>Phone Number</Typography>
                      </Grid>
                      <Grid>
                        <Field name="phone">
                          {({ field }: FieldProps) => (
                            <TextField
                              fullWidth
                              placeholder="+44 (0) 7123 456 789"
                              {...field}
                              variant="standard"
                              margin="dense"
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item>
                        <ErrorMessage name="phone">
                          {message => <Typography color="error">{message}</Typography>}
                        </ErrorMessage>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography>Password</Typography>
                      </Grid>
                      <Grid item>
                        <Field name="password">
                          {({ field }: FieldProps) => (
                            <TextField
                              fullWidth
                              {...field}
                              variant="standard"
                              margin="dense"
                              type="password"
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item>
                        <ErrorMessage name="password">
                          {message => <Typography color="error">{message}</Typography>}
                        </ErrorMessage>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Divider />
                  </Grid>
                  <Grid item>
                    <Typography variant="h6">User account</Typography>
                  </Grid>
                  <Grid item>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography>Role</Typography>
                      </Grid>
                      <Grid item>
                        <Field name="role">
                          {({ field }: FieldProps) => (
                            <Select
                              disableUnderline
                              {...field}
                              fullWidth
                              value={field.value}
                              variant="standard"
                              margin="dense"
                              error={touched.role && !!errors.role ? true : false}>
                              <MenuItem value="" selected disabled>
                                Select Role
                              </MenuItem>
                              <MenuItem value="super_admin">Super Admin</MenuItem>
                              <MenuItem value="client">Client</MenuItem>
                            </Select>
                          )}
                        </Field>
                      </Grid>
                      <Grid item>
                        <ErrorMessage name="role">
                          {message => <Typography color="error">{message}</Typography>}
                        </ErrorMessage>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <LoadingButton
                      onClick={() => handleSubmit()}
                      loadingIndicator={<Watch color="#063e84" width={30} height={20} />}
                      loading={loading}
                      variant="contained"
                      fullWidth>
                      Create User
                    </LoadingButton>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      disabled={loading}
                      sx={{
                        backgroundColor: '#ECEDF3',
                        color: '#333F48',
                        '&:hover': {
                          backgroundColor: '#ECEDF3',
                          color: '#333F48',
                          textDecoration: 'underline',
                          textDecorationThickness: '2px',
                          textDecorationColor: '#333F48',
                        },
                      }}
                      fullWidth
                      onClick={() => dispatch(set_create_user_drawer(false))}>
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};

export default CreateUser;

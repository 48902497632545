import { configureStore } from '@reduxjs/toolkit';
import adminReducer from './reducers/adminReducer';
import dialogReducer from './reducers/dialogReducer';
import projectReducer from './reducers/projectReducer';
import proofingReducer from './reducers/proofingReducer';
import userReducer from './reducers/userReducer';

const Store = configureStore({
  reducer: {
    user: userReducer,
    admin: adminReducer,
    project_data: projectReducer,
    dialog: dialogReducer,
    proofing: proofingReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof Store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof Store.dispatch;

export default Store;

import { AxiosPromise } from 'axios';
import api from 'helpers/axios-interceptor/api';

export const fetchUserList = (): AxiosPromise => {
  return api.get(`/users`);
};

export const deleteUser = (id: string): AxiosPromise => {
  return api.delete(`/users/${id}`);
};

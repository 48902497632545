import React, { useEffect, useState } from 'react';
import {
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  Button,
  Grid,
  Pagination,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserList } from './actions';
import TableFilter from 'Components/table-filter/table-filter.component';
import { set_user_details_drawer } from 'Store/reducers/adminReducer';
import { set_users, set_user_details } from 'Store/reducers/userReducer';
import { TableFilterObjectTypes } from 'shared/types/TableFilterObject.types';
import { AppDispatch, RootState } from 'Store/Store';
import { UserType } from 'shared/types/User.types';
const styles = require('./user-table.scss');

export const UserTable: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch<AppDispatch>();
  const users = useSelector((state: RootState) => state?.user?.users);
  const [filters, setFilters] = useState<TableFilterObjectTypes>({
    search: '',
    sort: 'name',
    perPage: 10,
    page: 1,
  });

  useEffect(() => {
    fetchUserList(filters).then(res => {
      if (res.status === 200) {
        dispatch(set_users(res.data));
      }
    });
  }, [filters, dispatch]);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <TableFilter
          title="User List"
          counter={users?.meta?.total}
          setFilters={setFilters}
          filters={filters}
        />
      </Grid>
      <Grid item>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TableContainer component={Paper} elevation={0} className={styles.data_table}>
              <Table size="medium">
                <TableHead>
                  <TableRow>
                    <TableCell className={styles.table_header} style={{ width: 100 }}>
                      User ID
                    </TableCell>
                    <TableCell className={styles.table_header}>First name</TableCell>
                    <TableCell className={styles.table_header}>Last Name</TableCell>
                    <TableCell className={styles.table_header}>Email</TableCell>
                    <TableCell className={styles.table_header}>Role</TableCell>
                    <TableCell className={styles.table_header} align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users &&
                    users?.data?.length > 0 &&
                    users?.data?.map((user: UserType, index: number) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row" style={{ width: 100 }}>
                          {user?.id}
                        </TableCell>
                        <TableCell>{user?.first_name}</TableCell>
                        <TableCell>{user?.last_name}</TableCell>
                        <TableCell>{user?.email}</TableCell>
                        <TableCell>{user?.role?.display_name}</TableCell>
                        <TableCell align="right">
                          <Button
                            variant="text"
                            style={{
                              color: 'black',
                            }}
                            onClick={() => {
                              dispatch(set_user_details_drawer(true));
                              dispatch(set_user_details(user));
                            }}>
                            View
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item>
            <Grid container direction="row" justifyContent="flex-end">
              <Grid item>
                <Pagination
                  count={users?.meta?.last_page}
                  shape="rounded"
                  onChange={(e, page) => {
                    setFilters(prevState => ({
                      ...prevState,
                      page: page,
                    }));
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserTable;

import { AxiosPromise } from 'axios';
import api from 'helpers/axios-interceptor/api';
import { TableFilterObjectTypes } from 'shared/types/TableFilterObject.types';

export const fetchUserList = ({
  search,
  sort,
  perPage,
  page = 1,
}: TableFilterObjectTypes): AxiosPromise => {
  return api.get(
    `/users${page && `?page=${page}`}${sort && `&sort=${sort}`}${perPage && `&perPage=${perPage}`}${
      search && `&search=${search}`
    }`,
  );
};

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { set_user } from 'Store/reducers/userReducer';
import api from '../helpers/axios-interceptor/api';

type AdminProtectedRouteType = {
  children: React.ReactElement;
};

const AdminProtectedRoute: React.FC<AdminProtectedRouteType> = ({
  children,
}: AdminProtectedRouteType) => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const dispatch = useDispatch();
  const route = useNavigate();

  useEffect(() => {
    if (user?.id) {
      api.post('/users/me', {}).then(res => {
        dispatch(set_user(res.data));
        localStorage.setItem('user', JSON.stringify(res.data));
      });
    }
  }, [dispatch, route, user]);

  if (user?.id && user?.is_super_admin) {
    return children;
  }

  return <Navigate to="/" />;
};

export default AdminProtectedRoute;
